import Translator from '../../translator'

let translatable = {
  data() {
    return {
      translator: null,
    }
  },
  methods: {
    translate(key, params = {}) {
      if (this.translator) {
        return this.translator.trans(key, params)
      }
      return '…'
    },
    hasTranslation(key) {
      if (this.translator) {
        return this.translator.hasTranslation(key)
      }
      return false
    },
  },
  mounted() {
    Translator.then((translator) => {
      this.translator = translator
    })
  },
}

export default translatable

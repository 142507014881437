export default {
  props: {
    classes: Array,
    size: [String, Number],
    fill: { type: String, default: 'none' },
    strokeWidth: { type: Number, default: 2 },
  },
  data() {
    return {
      color1: '#063462',
      color2: '#FF3944',
      color3: '#ffffff',
    }
  },
  computed: {
    iconSize() {
      if (!this.size) {
        return null
      }
      if (this.size.slice(-2) == 'px') {
        return this.size.slice(0, -2)
      } else if (this.size.slice(-2) == 'em') {
        return this.size.slice(0, -2) * 16
      } else {
        return this.size
      }
    },
  },
}

<template>
  <div :class='classes' :style='style'>
    <label :for='`input-${name}`' class='form-label' v-if='label'>{{ label }}</label>
    <input
      type='hidden'
      :name='`${name}[country]`'
    />
    <input
      type='tel'
      :name='`${name}[phone]`'
      :id='`input-${name}`'
      :required='required'
      :class="{'is-invalid': invalid}"
      ref='phone'
      class='form-control input-phone'
      @countrychange='onCountryChange'
      v-bind='$attrs'
      v-model='phone'
    />
    <div class='invalid-feedback' :class="{'d-block': formValidated && (required && !phone || invalid)  }">
      <span v-for='errorMessage in errorMessages'>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>

async function getUpdateInput() {
  const { updateInput } = await import('../../components/input-tel')

  return updateInput
}

export default {
  name: 'PhoneInput',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    classes: {
      type: String | Object | Array,
      default: null
    },
    style: {
      type: String | Object | Array,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    country: {
      type: String,
      default: null
    },
    errorMessages: {
      type: Array,
      default: null
    },
    formValidated: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phone: this.modelValue,
      ready: false
    }
  },
  computed: {
    phoneNumberWithPrefix() {
      if (window.intlTelInputGlobals.getInstance(this.$refs.phone)) {
        return '+' + window.intlTelInputGlobals.getInstance(this.$refs.phone).selectedCountryData.dialCode + this.phone
      }

      return null
    }
  },
  watch: {
    phone(newValue, oldValue) {
      this.$emit('update:modelValue', this.phoneNumberWithPrefix)
      this.$emit('update:hasValue', newValue.length > 0)
    }
  },
  methods: {
    parsePhoneNumber(phoneNumber) {
      if (phoneNumber?.slice(0, 1) === '+') {
        let country = window.intlTelInputGlobals.getCountryData().find((country) => {
          return country.dialCode == phoneNumber.slice(1, country.dialCode.length + 1)
        })
        return {
          phone: phoneNumber.slice(country.dialCode.length + 1),
          country: country.iso2
        }
      }

      return { phone: phoneNumber, country: null }
    },
    onCountryChange() {
      let country = window.intlTelInputGlobals.getInstance(this.$refs.phone).getSelectedCountryData().iso2.toUpperCase()
      let countryCode = window.intlTelInputGlobals.getInstance(this.$refs.phone).getSelectedCountryData().dialCode
      this.$emit('update:country', country)
      this.$emit('update:modelValue', `+${countryCode}${this.phone}`)
      this.$emit('update:hasValue', this.phone?.length > 0)
    }
  },
  mounted() {
    getUpdateInput().then((updateInput) => {
      let { phone } = this.parsePhoneNumber(this.modelValue)

      this.phone = phone

      updateInput(this.$refs.phone)

      this.$nextTick(() => {
        this.onCountryChange()
      })
    })
  }
}
</script>

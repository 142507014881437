<template>
  <div class="position-relative h-100 w-100 bg-grey5">
    <div
        v-for="n in 8"
        class="position-absolute bg-secondary"
        style="border-radius: 50%; animation: ball-spin 1s infinite; height: 22px; width: 22px;"
        :style="{
          top: getTop(n),
          left: getLeft(n),
          animationDelay: getDelay(n),
        }">&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  methods: {
    getTop(n) {
      return `calc(50% + ${3 * Math.cos(Math.PI * (n / 4))}rem)`;
    },
    getLeft(n) {
      return `calc(50% + ${3 * Math.sin(Math.PI * (n / 4))}rem)`;
    },
    getDelay(n) {
      return `-${(8-n) / 8}s`
    }
  }
}
</script>

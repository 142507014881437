<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
          d="M6.91883 13.3231C9.30727 13.3231 13.185 13.7438 17.737 15.5669C14.7304 17.1657 11.4147 17.5023 9.13867 17.5023C5.96346 17.5023 4.19321 17.5023 4.19321 15.034C4.16511 14.1365 4.97999 13.3231 6.91883 13.3231ZM7.05933 21.962C8.40809 21.962 25.099 22.551 27.656 5.86215L30.6065 9.22797L33.304 6.8158L26.223 0L18.3833 6.67555L20.9403 9.22797L24.5089 5.7219C23.8626 9.17188 22.4577 11.6401 20.7155 13.3792C16.0792 10.5743 11.1337 8.63895 6.55354 8.63895C4.16511 8.63895 2.22627 8.83529 1.2709 9.81699C0.203126 10.9109 0.231225 12.8743 0.231225 14.6694C0.175027 19.6901 2.22627 21.962 7.05933 21.962Z"
          class="fill-color2" :fill="color2"/>
      <path
          d="M45.8616 31.5272C45.8616 32.4245 45.0467 33.2382 43.1079 33.2382C40.7195 33.2382 36.8418 32.8175 32.2897 30.9943C35.2963 29.3953 38.612 29.0587 40.8881 29.0587C42.6864 29.0587 44.4567 28.7782 45.2996 29.255C45.9768 29.5638 45.8644 30.4611 45.8644 31.5272H45.8616ZM42.9955 24.5709C41.6467 24.5709 24.9558 23.9822 22.3988 40.6711L19.4484 37.3052L16.7509 39.7171L23.8319 46.5332L31.6996 39.8296L29.1426 37.2772L25.574 40.7833C26.2203 37.333 27.6252 34.8647 29.3674 33.1257C34.0038 35.9306 38.9492 37.8662 43.5294 37.8662C45.7211 37.8662 47.6319 38.0342 48.5029 37.165C49.374 36.2955 49.8517 34.5565 49.8517 31.8358C49.8517 29.1989 50.0484 27.0953 48.812 25.8892C47.6909 24.8514 45.2743 24.5709 42.9955 24.5709Z"
          class="fill-color2" :fill="color2"/>
      <path
          d="M49.0958 10.7137C47.9719 9.42347 45.6115 9.53567 43.1107 9.53567C38.5024 9.53567 33.276 11.5271 28.5272 16.6039L25.8297 15.1734C25.2087 16.0901 24.5231 16.9618 23.7784 17.7819L26.2512 19.2124L23.5537 22.5782C19.8727 27.066 16.6974 30.5998 12.6793 31.9465C9.78505 32.984 5.17678 32.9562 4.3619 32.3952C3.85612 32.0306 3.99661 31.0206 3.99661 30.0672C3.99661 27.5989 7.9305 26.6453 16.6693 27.9352L18.8611 25.6636C14.7305 24.4292 10.4594 23.6721 6.32884 23.6721C2.19826 23.6721 0.00371559 25.6636 0.00371559 29.7306C0.00371559 32.1425 -0.108681 34.6391 0.90289 35.8452C2.02686 37.1354 4.38719 37.0232 6.88802 37.0232C11.4963 37.0232 16.7227 35.0318 21.4715 29.955L24.169 31.3855C24.79 30.4686 25.4756 29.5968 26.2203 28.7767L23.7475 27.3465L26.4451 23.9807C30.1261 19.4929 33.3013 15.9588 37.3195 14.6125C40.2137 13.5747 44.8219 13.6027 45.6368 14.1637C46.1426 14.5283 46.0021 15.5381 46.0021 16.4917C46.0021 18.9597 42.0682 19.9136 33.3294 18.6234L31.1376 20.8953C35.2682 22.1295 39.5393 22.8865 43.6699 22.8865C47.8004 22.8865 49.9922 20.8953 49.9922 16.8283C49.995 14.4161 50.1355 11.8917 49.0958 10.7137Z"
          class="fill-color2" :fill="color2"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Logo",
  mixins: [svgIcon],
}
</script>